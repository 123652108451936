import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Divider, Flex, Grid, GridItem, HStack, Heading, Image, Link, ListItem, Spacer, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, UnorderedList, VStack, Wrap, WrapItem, useToast } from "@chakra-ui/react";
import { NavBar } from "../components/NavBar";
import { ArbNovaIcon, BaseIcon, CkbIcon, EthIcon, GodwokenIcon, JoyIDIcon, LineaIcon, MetaMaskIcon, OpBnbIcon, PgnIcon, PolygonIcon, ScrollIcon, StarkNetIcon, TaikoIcon, ZkSyncIcon, ZoraIcon } from "../icons/Icons";
import { Footer } from "../components/Footer";
import { useEffect, useRef, useState } from "react";
//import ParticleEffectButton from 'react-particle-effect-button';
import { delay } from "../utils/threads";
import React from "react";
import { DisclaimerCard } from "../components/DisclaimerCard";

export const HomePage = () => {
    const toast = useToast();


    useEffect(() => {
        init();
    });

    const init = async () => {
    }

    return (
        <VStack spacing={4} maxW="960px" mx="auto" h="800px">
            <NavBar />
            <Grid width="100%" templateRows='repeat(2, 1fr)' gap={2}>
                <GridItem w='100%' h='200px'>
                    <DisclaimerCard></DisclaimerCard>
                </GridItem>

                <GridItem w='100%' h='300px'>
                    <Center>
                        <Image src="/images/001.gif" />
                    </Center>
                </GridItem>

                {/* <GridItem w='100%' h='200px'>
                    <DisclaimerCard></DisclaimerCard>
                </GridItem> */}
            </Grid>
            <Footer />
        </VStack>
    );
}