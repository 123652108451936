import { Card, CardBody, CardFooter, CardHeader, Heading, Text } from "@chakra-ui/react";

export const DisclaimerCard = () => {
    return (
    <Card align='center'>
        <CardHeader>
            <Heading size='md'>没有赋能！没有赋能！没有赋能！</Heading>
        </CardHeader>
        <CardBody>
            <Text>😏😏😏 仅用于增加链上TX！  😏😏😏</Text>
        </CardBody>
        <CardFooter>
            <Heading size='md' textColor="gray">没有赋能！没有赋能！没有赋能！</Heading>
        </CardFooter>
    </Card>
    );
}