import { ethers } from "ethers";

export interface IWallet {
    connect(): Promise<string>;
    signMessage(message: string): Promise<string>;
    verifyMessage(): Promise<boolean>;
}

export class Wallet implements IWallet {
    walletProvider: string = "";
    account: string = ""; // ckb address
    originalAccount: string = ""; // eth/ckb address
    publicKey: string = "";
    
    async connect(): Promise<string>{
        return "";
    }

    async signMessage(message: string): Promise<string>{
        return "";
    }
    
    async verifyMessage(): Promise<boolean> {
        return false;
    }
    
}

export class EvmWallet extends Wallet {
    provider: ethers.BrowserProvider;
    //signer: ethers.JsonRpcSigner;
    chainId: BigInt = BigInt(1);
    address: string = "";
    addrInt: bigint = BigInt(0);

    constructor(){
        super();
        this.provider = new ethers.BrowserProvider((window as any).ethereum);
    }

    async switchNetwork(networkName: string, networkInfo: any): Promise<boolean> {
        return false;
    }

    async mint(contract: ethers.Contract, toast: any): Promise<string> {
        return "";
    }

    async getContract(contractAddress: string, abi: any) {
        const signer = await this.provider.getSigner(this.account);
        const c = new ethers.Contract(contractAddress, abi, signer);
        return {
            signer,
            contract: c
        };
    }
    
    async getBalance(abi: any, contractAddress: string, ethAddress: string): Promise<string> {
        const p = this.provider;// new ethers.JsonRpcProvider(rpc);
        const contract = new ethers.Contract(contractAddress, abi, p);
        const balanceBigint = await contract.balanceOf(ethAddress);
        //return parseInt(`${balanceBigint}`);
        return balanceBigint.toString();
    }

}