//import { Chain, defineChain } from 'viem';
import { base, baseSepolia, blast, blastSepolia, scroll, scrollSepolia, } from 'viem/chains';
// import contractArbNova from "../chains/arbitrum-nova/artifacts/contracts/DDDD.sol/DDDD.json";
import contractBase from "../chains/base/artifacts/contracts/DDDD.sol/DDDD.json";
import contractBlast from "../chains/blast/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractEthereum from "../chains/ethereum/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractGodwoken from "../chains/godwoken/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractLinea from "../chains/linea/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractOpBnb from "../chains/opbnb/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractPolygonZkEvm from "../chains/polygon-zkevm/artifacts/contracts/DDDD.sol/DDDD.json";
import contractScroll from "../chains/scroll/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractTaiko from "../chains/taiko/artifacts/contracts/DDDD.sol/DDDD.json";
// import contractZkSync from "../chains/zksync/artifacts-zk/contracts/DDDD.sol/DDDD.json";
// import contractZora from "../chains/zora/artifacts/contracts/DDDD.sol/DDDD.json";

export const ChainsInfo = {
    useMainnet: false,
    ethereum: {
        currency: "ETH",
        //abi: contractEthereum.abi,
        testnet: {
            chainId: BigInt(11155111),
            requestParams: {
                chainId: "0xaa36a7",
                rpcUrl: "https://rpc.sepolia.org"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(1),
            requestParams: {
                chainId: "0x1",
                rpcUrl: "https://1rpc.io/eth"
            },
            contract: "",
            explorer:""
        }
    },
    arbNova: {
        currency: "ETH",
        //abi: contractArbNova.abi,
        testnet: {
            chainId: BigInt(421614),
            requestParams: {
                chainId: "0x66eee",
                rpcUrl: "https://sepolia-rollup.arbitrum.io/rpc"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(42170),
            requestParams: {
                chainId: "0xa4ba",
                rpcUrl: "https://nova.arbitrum.io/rpc"
            },
            contract: "",
            explorer:""
        }
    },
    base: {
        name: "base",
        currency: "ETH",
        abi: contractBase.abi,
        testnet: {
            name: "Base Sepolia",
            chainId: BigInt(84532),
            requestParams: {
                chainId: "0x14a34",
                rpcUrl: "https://sepolia.base.org"
            },
            contract: "0xb5E135D44D19356E76533e0e3359aCffa9192485",
            explorer: "https://sepolia.basescan.org/"
        },
        mainnet: {
            name: "Base",
            chainId: BigInt(8453),
            requestParams: {
                chainId: "0x2105",
                rpcUrl: "https://mainnet.base.org"
            },
            contract: "0x286673DC1B4c9d25cD6204C58802726E25baAcC4",
            explorer: "https://basescan.org/"
        }
    },
    blast: {
        currency: "ETH",
        abi: contractBlast.abi,
        testnet: {
            chainId: BigInt(168587773),
            requestParams: {
                chainId: "0xa0c71fd",
                rpcUrl: "https://sepolia.blast.io"
            },
            contract: "0xD8C888476329D3acf623045063E5551F835a7515",
            explorer:"https://sepolia.blastscan.io/"
        },
        mainnet: {
            chainId: BigInt(81457),
            requestParams: {
                chainId: "0x13e31",
                rpcUrl: "https://rpc.blast.io"
            },
            contract: "0x2C55ef3C0F8F01bCFE70a5357E4E118f3D7abe04",
            explorer:"https://blastscan.io/"
        }
    },
    godwoken: {
        currency: "CKB",
        //abi: contractGodwoken.abi,
        testnet: {
            chainId: BigInt(71401),
            requestParams: {
                chainId: "0x116e9",
                rpcUrl: "https://v1.testnet.godwoken.io/rpc"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(71402),
            requestParams: {
                chainId: "0x116ea",
                rpcUrl: "https://v1.mainnet.godwoken.io/rpc"
            },
            contract: "",
            explorer:""
        }
    },
    linea: {
        currency: "ETH",
        //abi: contractLinea.abi,
        testnet: {
            chainId: BigInt(59141),
            requestParams: {
                chainId: "0xe705",
                rpcUrl: "https://rpc.sepolia.linea.build"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(59144),
            requestParams: {
                chainId: "0xe708",
                rpcUrl: "https://rpc.linea.build/"
            },
            contract: "",
            explorer:""
        }
    },
    opBnb: {
        currency: "BNB",
        //abi: contractOpBnb.abi,
        testnet: {
            chainId: BigInt(5611),
            requestParams: {
                chainId: "0x15eb",
                rpcUrl: "https://opbnb-testnet-rpc.bnbchain.org/"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(204),
            requestParams: {
                chainId: "0xcc",
                rpcUrl: "https://opbnb-mainnet-rpc.bnbchain.org"
            },
            contract: "",
            explorer:""
        }
    },
    polygon: {
        currency: "MATIC",
        //abi: contractPolygonZkEvm.abi,
        testnet: {
            chainId: BigInt(80001),
            requestParams: {
                chainId: "0x13881",
                rpcUrl: "https://polygon-mumbai-bor.publicnode.com"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(137),
            requestParams: {
                chainId: "0x89",
                rpcUrl: "https://1rpc.io/matic"
            },
            contract: "",
            explorer:""
        }
    },
    polygonZkEvm: {
        currency: "ETH",
        //abi: contractPolygonZkEvm.abi,
        testnet: {
            chainId: BigInt(1442),
            requestParams: {
                chainId: "0x5a2",
                rpcUrl: "https://rpc.public.zkevm-test.net"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(1101),
            requestParams: {
                chainId: "0x44d",
                rpcUrl: "https://zkevm-rpc.com"
            },
            contract: "",
            explorer:""
        }
    },
    scroll: {
        currency: "ETH",
        abi: contractScroll.abi,
        testnet: {
            chainId: BigInt(534351),
            requestParams: {
                chainId: "0x8274f",
                rpcUrl: "https://sepolia-rpc.scroll.io"
            },
            contract: "0xfdFc440dB7D44929b2cc313212d2eE0D7F3BfffC",
            explorer:"https://sepolia.scrollscan.com/"
        },
        mainnet: {
            chainId: BigInt(534352),
            requestParams: {
                chainId: "0x82750",
                rpcUrl: "https://rpc.scroll.io"
            },
            contract: "0x286673DC1B4c9d25cD6204C58802726E25baAcC4",
            explorer:"https://scrollscan.com/"
        }
    },
    taiko: {
        currency: "ETH",
        //abi: contractTaiko.abi,
        testnet: {
            chainId: BigInt(167009),
            requestParams: {
                chainId: "0x28c61",
                rpcUrl: "https://rpc.hekla.taiko.xyz"
            },
            contract: "",
            explorer:"https://hekla.taikoscan.network"
        },
        mainnet: {
            chainId: BigInt(167000),
            requestParams: {
                chainId: "0x28c58",
                rpcUrl: "https://rpc.mainnet.taiko.xyz"
            },
            contract: "",
            explorer:"https://taikoscan.io"
        }
    },
    zksync: {
        currency: "ETH",
        //abi: contractZkSync.abi,
        testnet: {
            chainId: BigInt(300),
            requestParams: {
                chainId: "0x12c",
                rpcUrl: "https://sepolia.era.zksync.dev"
            },
            contract: "",
            explorer:"https://sepolia.explorer.zksync.io"
        },
        mainnet: {
            chainId: BigInt(324),
            requestParams: {
                chainId: "0x144",
                rpcUrl: "https://mainnet.era.zksync.io"
            },
            contract: "",
            explorer:"https://explorer.zksync.io"
        }
    },
    zora: {
        currency: "ETH",
        //abi: contractZora.abi,
        testnet: {
            chainId: BigInt(999),
            requestParams: {
                chainId: "0x3E7",
                rpcUrl: "https://testnet.rpc.zora.energy/"
            },
            contract: "",
            explorer:""
        },
        mainnet: {
            chainId: BigInt(7777777),
            requestParams: {
                chainId: "0x76adf1",
                rpcUrl: "https://rpc.zora.energy/"
            },
            contract: "",
            explorer:""
        }
    }
}

export const EvmChains = [
    baseSepolia, blastSepolia, scrollSepolia,
    base, blast, scroll,
];