import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Divider, Flex, Grid, GridItem, HStack, Heading, Image, Link, ListItem, Spacer, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, UnorderedList, VStack, Wrap, WrapItem, useToast } from "@chakra-ui/react";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { ArbNovaIcon, BaseIcon, BlastIcon, CkbIcon, EthIcon, GodwokenIcon, JoyIDIcon, LineaIcon, MetaMaskIcon, OpBnbIcon, PgnIcon, PolygonIcon, ScrollIcon, StarkNetIcon, TaikoIcon, ZkSyncIcon, ZoraIcon } from "../icons/Icons";
import { ChainsInfo } from "../data/ChainsInfo";
import { ChainCardHeader, ChainNetworkBadge } from "../components/ChainNetworkBadge";
import { RoutesData } from "../data/RoutesData";
import { ViewData } from "../data/ViewData";

export const ChainSelectorPage = () => {
    const [connected, setConnected] = useState(ViewData.evmWallet !== null);
    const nav = useNavigate();
    const toast = useToast();

    const navgateTo = async (chainInfo: any) => {
        // switch chain
        const wallet = ViewData.evmWallet;
        if(!wallet) {
            toast({
                title: 'Error',
                description: "Please Connect your wallet!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        const isTestnet = chainInfo.mainnet.contract.length === 0;
        const networkInfo = isTestnet ? chainInfo.testnet : chainInfo.mainnet;
        const chainIdHex = networkInfo.requestParams.chainId;
        const r = await wallet.switchNetwork(chainIdHex, networkInfo);
        if(!r) {
            toast({
                title: 'Error',
                description: "Some error. Or you cancelled.",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        ViewData.chainInfo = chainInfo;
        ViewData.evmNetworkId = chainIdHex;
        ViewData.evmNetworkInfo = networkInfo;
        // navigate
        nav(RoutesData.Start);
    }

    return (
        <VStack spacing={4} maxW="960px" mx="auto" h="800px">
            <NavBar />
            <Wrap spacing={4}>
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Ethereum" icon={null} mainnetInfo={ChainsInfo.ethereum.mainnet} />
                        <CardBody>
                            <Center>
                                <EthIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.ethereum.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Arbitrum Nova" icon={null} mainnetInfo={ChainsInfo.arbNova.mainnet} />
                        <CardBody>
                            <Center>
                                <ArbNovaIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.arbNova.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Base" icon={null} mainnetInfo={ChainsInfo.base.mainnet} />
                        <CardBody>
                            <Center>
                                <BaseIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.base)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem>
                <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Blast" icon={null} mainnetInfo={ChainsInfo.blast.mainnet} />
                        <CardBody>
                            <Center>
                                <BlastIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow" isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.blast)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem>
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Godwoken" icon={null} mainnetInfo={ChainsInfo.godwoken.mainnet} />
                        <CardBody>
                            <Center>
                                <GodwokenIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.godwoken.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Linea" icon={null} mainnetInfo={ChainsInfo.linea.mainnet} />
                        <CardBody>
                            <Center>
                                <LineaIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.linea.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="opBNB" icon={null} mainnetInfo={ChainsInfo.opBnb.mainnet} />
                        <CardBody>
                            <Center>
                                <OpBnbIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.opBnb.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Polygon zkEVM" icon={null} mainnetInfo={ChainsInfo.polygonZkEvm.mainnet} />
                        <CardBody>
                            <Center>
                                <PolygonIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.polygonZkEvm.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Scroll" icon={null} mainnetInfo={ChainsInfo.scroll.mainnet} />
                        <CardBody>
                            <Center>
                                <ScrollIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.scroll)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem>
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Taiko" icon={null} mainnetInfo={ChainsInfo.taiko.mainnet} />
                        <CardBody>
                            <Center>
                                <TaikoIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.taiko.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="zkSync" icon={null} mainnetInfo={ChainsInfo.zksync.mainnet} />
                        <CardBody>
                            <Center>
                                <ZkSyncIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.zksync.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
                {/* <WrapItem>
                    <Card w="260px" h="320px">
                        <ChainCardHeader title="Zora" icon={null} mainnetInfo={ChainsInfo.zora.mainnet} />
                        <CardBody>
                            <Center>
                                <ZoraIcon w="96px" h="96px" />
                            </Center>
                        </CardBody>
                        <CardFooter>
                            <Button colorScheme="yellow"  isDisabled={!connected}
                                onClick={e => navgateTo(ChainsInfo.zora.mainnet)}>Select</Button>
                        </CardFooter>
                    </Card>
                </WrapItem> */}
            </Wrap>
            <Footer />
        </VStack>
    );
}