import { AppSettings } from "./AppSettings";
import { EvmWallet } from './Wallet';
import { ProviderKeys } from '../data/ProviderKeys';
import { Signer, ethers, parseEther } from "ethers";

export class MmWallet extends EvmWallet {
    signer: Signer | null = null;
    constructor() {
        super();
        this.walletProvider = ProviderKeys.InjectedWallet;
    }

    async init(requestChainId: BigInt): Promise<boolean> {
        const network = await this.provider.getNetwork();
        if(requestChainId !== network.chainId) {
            return false;
        }
        this.chainId = network.chainId;
        return true;
    }

    async connect(): Promise<string> {
        this.signer = await this.provider.getSigner();
        this.account = await this.signer.getAddress();
        this.address = this.account;
        this.addrInt = ethers.getBigInt(this.account);

        this.originalAccount = this.account;
        console.log(`EVM address: ${this.account}`);
        console.log(`publicKey: ${this.publicKey}`);
        return this.account;
    }

    async signMessage(message: string): Promise<string> {
        let signature = await this.signer?.signMessage(message);
        return signature || "";
    }

    async verifyMessage(): Promise<boolean> {
        return false;
    }

    override async switchNetwork(networkName: string, networkInfo: any): Promise<boolean> {
        const ethereum = (window as any).ethereum;
        // Check if MetaMask is installed
        // MetaMask injects the global API into window.ethereum
        if (ethereum) {
            try {
                // check if the chain to connect to is installed
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: networkInfo.requestParams.chainId }], // chainId must be in hexadecimal numbers
                });
                //this.provider = new ethers.BrowserProvider(ethereum, {chainId: networkInfo.chainId });
                return true;
            } catch (error: any) {
                // This error code indicates that the chain has not been added to MetaMask
                // if it is not, then install it into the user MetaMask
                if (error.code === 4902) {
                    try {
                        await ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [ networkInfo.requestParams ],
                        });
                    } catch (addError) {
                        console.error(addError);
                    }
                }
                else if(error.code !== -32002) {
                    console.log(error);
                }
                return false;
            }
        } else {
            // if no window.ethereum then MetaMask is not installed
            alert('MetaMask is not installed. Please consider installing it: https://metamask.io');
            return false;
        }

        //const currentNetwork = await this.provider.getNetwork();
        // const currentNetwork = await this.provider._detectNetwork();
        
        // return currentNetwork.chainId === networkInfo.chainId;
    }

    override async mint(contract: ethers.Contract, toast: any): Promise<string> {
        try{
            let gasPrice = BigInt(0);
            if(this.provider.getFeeData){
                const feeData = await this.provider.getFeeData();
                gasPrice = feeData.gasPrice || 0n;
            }
            //else if(this.provider.getGasPrice)
            
            //console.log(`gas price: ${feeData.gasPrice}`);
            const gasLimit = await contract.mint.estimateGas({});//{value: 0}
            console.log(`gas limit: ${gasLimit}`);
            const param = gasPrice === 0n ? {
                //value: 0,
                gasLimit: gasLimit + 3000n
            } : {
                //value: 0,
                gasLimit: gasLimit + 3000n,
                gasPrice: gasPrice + gasPrice * 10n / 100n
            };
            const tx = await contract.mint(param);
            // receipt对象通常用于检查交易是否成功执行以及获取交易执行后的状态，例如事件日志和交易消耗的gas量等。
            // 如果你需要检查交易是否成功，可以检查receipt.status属性，它返回一个表示交易是否成功的布尔值。
            // 如果receipt.status为1，则表示交易成功，如果为0，则表示交易失败。
            const receipt = await tx.wait();
            //console.log(receipt);
            return tx.hash;//receipt.blockHash;
        }
        catch(error: any) {
            if(error.code === 4001) {
                toast({
                    title: 'Cancelled!',
                    description: "You cancelled the action!",
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
            return "";
        }
    }

}
