import { EvmConfig, connect, getConfig, initConfig, sendTransaction, signMessage  } from "@joyid/evm";
import { EthereumProvider } from '@joyid/ethereum-provider';
import { AppSettings } from "./AppSettings";
import { EvmWallet } from './Wallet';
import { ProviderKeys } from '../data/ProviderKeys';
import { ethers, BrowserProvider } from "ethers";
import { ChainsInfo, EvmChains } from "../data/ChainsInfo";

export class JoyIDEvmWallet extends EvmWallet {
    joyidAppURL: string =  "https://app.joy.id";
    config: EvmConfig = getConfig();
    c = {
        rpcURL: ChainsInfo.base.testnet.requestParams.rpcUrl,
        network: {
            name: "Base - Sepolia",
            chainId: Number(ChainsInfo.base.testnet.chainId),
        },
        // name of your app
        name: AppSettings.appName,
        // logo of your app
        logo: AppSettings.appIcon,
        // JoyID app url that your app is integrated with
        joyidAppURL: this.joyidAppURL,
    };
    ethProvider = new EthereumProvider(EvmChains, this.c);

    constructor() {
        super();
        this.walletProvider = ProviderKeys.JoyID;
        initConfig({
            // name of your app
            name: AppSettings.appName,
            // logo of your app
            logo: AppSettings.appIcon,
            // JoyID app url that your app is integrated with
            joyidAppURL: this.joyidAppURL,
        });
        this.provider = new BrowserProvider(this.ethProvider);
    }

    async connect(): Promise<string> {
        this.account = "";
        this.account = await connect();
        this.originalAccount = this.account;
        this.address = this.account;
        return this.account;
    }

    async signMessage(message: string): Promise<string> {
        let signature = await signMessage(message, this.account);
        return signature;
    }

    async verifyMessage(): Promise<boolean> {
        return false;
    }

    async switchNetwork(networkName: string, networkInfo: any) : Promise<boolean> {
        const requestChainId = Number(networkInfo.chainId);
        const currentChainId = this.ethProvider.getChainId();
        if(requestChainId === currentChainId) return true;

        const chainRequested = this.ethProvider.switchChain(Number(networkInfo.chainId));
        console.log("chainRequested:");
        console.log(chainRequested);

        this.provider = new BrowserProvider(this.ethProvider);
        const r = this.config.network?.chainId === networkInfo.chainId;
        console.log(`equal: ${r}`);
        return r;
    }

    async mint(contract: ethers.Contract, toast: any): Promise<string> {
        try{
            //const feeData = await this.provider.getFeeData();
            //console.log(`gas price: ${feeData.gasPrice}`);
            const gasLimit = await contract.mint.estimateGas({value: 0});
            console.log(`gas limit: ${gasLimit}`);
            const tx = await contract.mint({
                value: 0,
                gasLimit: gasLimit,
                // gasPrice: feeData.gasPrice
            });
            const receipt = await tx.wait();
            return receipt.blockHash;
        }
        catch(error: any) {
            if(error.code === 4001) {
                toast({
                    title: 'Cancelled!',
                    description: "You cancelled the action!",
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
            return "";
        }
    }

}
