import React, { useEffect } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Link,
  Container,
  useDisclosure,
  //useColorModeValue,
  Stack,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Spinner,
  Text,
  useToast,
  Avatar,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Logo } from '../icons/Logo';
import { RoutesData } from '../data/RoutesData';
import { FaUser } from 'react-icons/fa';
import { ViewData } from '../data/ViewData';
import { BlastIcon, JoyIDIcon, MetaMaskIcon, StarkNetIcon } from '../icons/Icons';
import { JoyIDEvmWallet } from '../client/JoyIDEvmWallet';
import { MmWallet } from "../client/MmWallet";
import { ViewModelBridge } from '../client/ViewModelBridge';
import { EvmWallet, Wallet } from '../client/Wallet';
import { EncodingUtils } from '../utils/EncodingUtils';
import { DotbitContext } from '../client/DotbitContext';
import { ChainsInfo } from '../data/ChainsInfo';
//import { ChainsInfo } from '../data/ChainsInfo';

export const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenOverlay, onOpen: onOpenOverlay, onClose: onCloseOverlay } = useDisclosure();
  const [account, setAccount] = React.useState(ViewData.evmWallet?.account || "");
  const [joyidLogged, setJoyidLogged] = React.useState(ViewData.loggedWithJoyID);
  const [displayName, setDisplayName] = React.useState("");
  const [currentNetworkId, setCurrentNetworkId] = React.useState("");
  const [currentNetworkName, setCurrentNetworkName] = React.useState("[Select one network]");
  const nav = useNavigate();
  const toast = useToast();

  const getDIDs = async () => {
    const cxtDotbit = new DotbitContext();
    try {
      await cxtDotbit.useAddress(ViewData.ethAddress);
    }
    finally { }

    // const cxtEns = new ENSContext(provider);
    // try {
    //   await cxtEns.useAddress(ViewData.eth);
    //   ViewData.did.ens = cxtEns.did;
    //   ViewMdoelBridge.ENSContext = cxtEns;
    // }
    // finally { }

    ViewData.did = cxtDotbit.did || account.substring(account.length - 4);
    setDisplayName(ViewData.did);

    console.log(`DID: ${ViewData.did}`);
  };
  const afterConnected = (wallet: EvmWallet, addr: string) => {
    setAccount(addr);
    console.log(`${addr} logged in.`);

    ViewData.evmWallet = wallet;
    if (ViewModelBridge.afterConnected)
      ViewModelBridge.afterConnected();

    nav(RoutesData.SelectChain);
  }

  // const doLoadEvmStatus = async (chainInfo: any) => {
  //   const testnet = chainInfo.mainnet.contract.length === 0;
  //   const networkInfo = testnet ? chainInfo.testnet : chainInfo.mainnet;
  //   return await EvmWorker.checkMinted(networkInfo.requestParams.rpcUrl, chainInfo.abi, networkInfo.contract, ViewData.ethAddress);
  // }


  const connectEvmWithJoyID = async () => {
    const wallet = new JoyIDEvmWallet();
    const addr = await wallet.connect();
    ViewData.ethAddress = wallet.account;
    afterConnected(wallet, addr);
    ViewData.loggedWithJoyID = true;
    setJoyidLogged(ViewData.loggedWithJoyID);
    //await getDIDs();
  }
  const connectWithMm = async () => {
    const wallet = new MmWallet();
    const addr = await wallet.connect();
    ViewData.ethAddress = wallet.originalAccount;
    afterConnected(wallet, addr);
    //await getDIDs();
  }

  const tryDisconnect = async () => {
    setAccount("");
    setJoyidLogged(false);
    ViewData.evmWallet = null;
    ViewData.ethAddress = "";
    ViewData.isEvmDeployer = false;

    if (ViewModelBridge.afterDisConnected)
      ViewModelBridge.afterDisConnected();

    nav(RoutesData.Home);
  }

  return (
    <>
      <Box w="100%" px={4}>
        <Container as={Stack} maxW={'6xl'}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8}>
              <Text>🙏 DDDD</Text>
              {/* <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                <Link as={ReactLink} to={RoutesData.Home}>Home</Link>
              </HStack> */}
            </HStack>

            <Flex alignItems={'center'}>
              <Stack direction={'row'} spacing={7}>
                {account && account.length > 4 ? <Menu>
                  <MenuButton colorScheme='cyan' size="lg"
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <HStack>
                      <Avatar name={account} src={`https://robohash.org/${account}.png?set=set1`} />
                      <Text>{displayName}</Text>
                    </HStack>
                  </MenuButton>
                  <MenuList alignItems={'center'}>
                    <MenuItem onClick={tryDisconnect}>Disconnect</MenuItem>
                  </MenuList>
                </Menu> : <Menu>
                  <MenuButton colorScheme='cyan' size="lg"
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}
                  >Connect</MenuButton>
                  <MenuList alignItems={'center'}>
                    <MenuItem icon={<JoyIDIcon />} isDisabled={false} onClick={connectEvmWithJoyID}>JoyID</MenuItem>
                    <MenuItem icon={<MetaMaskIcon />} onClick={connectWithMm}>MetaMask</MenuItem>
                  </MenuList>
                </Menu>}
                {/* <ColorModeSwitcher /> */}
              </Stack>
            </Flex>
          </Flex>
        </Container>
        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              <Link as={ReactLink} to={RoutesData.Home}>Home</Link>
            </Stack>
          </Box>
        ) : null}
      </Box>
      <Modal isCentered isOpen={isOpenOverlay} onClose={onCloseOverlay}>
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(90deg)'
        />
        <ModalContent>
          <ModalHeader>Loading</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Center>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose}>Close</Button> */}
            <Text>Please wait...</Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};