import * as React from "react"
import {
  ChakraProvider,
  Box, Center, Grid,
  extendTheme,
} from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StartPage } from "./pages/StartPage";
import { ChainSelectorPage } from "./pages/ChainSelectorPage";
import { HomePage } from "./pages/HomePage";
import { RoutesData } from "./data/RoutesData";
import ParticlesBg from 'particles-bg';
import { base64BtcPng } from "./icons/Icons";
//import svgBtc from "./icons/btc.svg";


const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: '#eeeeee',
        //color: 'gold',
      },
      // styles for the `a`
      a: {
        _hover: {
          textDecoration: 'none',
        },
      },
    },
  },
});

//const base64Btc = window.btoa(svgBtc);
const pbgConfig = {
  num: [1,3],
  rps: 0.1,
  radius: [5, 40],
  life: [1.5, 3],
  v: [0.08, 2.2],
  tha: [-50, 50],
  body: base64BtcPng, //`data:image/svg+xml;base64, ${base64Btc}`, // Whether to render pictures
  // rotate: [0, 20],
  alpha: [0.66, 0],
  scale: [0.05, 0.4],
  position: "all", // all or center or {x:1,y:1,width:100,height:100}
  cross: "dead", // cross or bround
  random: 8,  // or null,
  g: 0,    // gravity
  // f: [2, -1], // force
  color: ["random"],
};

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box fontSize="xl" width="100%" height="100%">
      <BrowserRouter>
        <Routes>
          <Route path={RoutesData.Start} element={<StartPage />} />
          <Route path={RoutesData.SelectChain} element={<ChainSelectorPage />} />
          <Route path={RoutesData.Home} element={<HomePage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
      <ParticlesBg type="custom" config={pbgConfig} bg={true} />
    </Box>
  </ChakraProvider>
);