import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Divider, Flex, Grid, GridItem, HStack, Heading, Image, Link, ListItem, Spacer, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, UnorderedList, VStack, Wrap, WrapItem, useToast } from "@chakra-ui/react";
import { NavBar } from "../components/NavBar";
import { ArbNovaIcon, BaseIcon, CkbIcon, EthIcon, GodwokenIcon, JoyIDIcon, LineaIcon, MetaMaskIcon, OpBnbIcon, PgnIcon, PolygonIcon, ScrollIcon, StarkNetIcon, TaikoIcon, ZkSyncIcon, ZoraIcon } from "../icons/Icons";
import { Footer } from "../components/Footer";
import { useEffect, useRef, useState } from "react";
//import ParticleEffectButton from 'react-particle-effect-button';
import { delay } from "../utils/threads";
import { ViewData } from "../data/ViewData";
import { ethers } from "ethers";
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { RoutesData } from "../data/RoutesData";

export const StartPage = () => {
    const NotSet: string = "[Not Set]";
    const chainInfo = ViewData.chainInfo;
    const isTestnet = chainInfo.mainnet.contract.length === 0;
    const networkInfo = ViewData.evmNetworkInfo;
    const abi = chainInfo.abi;
    const [minting, setMinting] = useState(false);
    const [randomText, setRandomText] = useState(NotSet);
    const [theHash, setTheHash] = useState(NotSet);
    const [prize, setPrize] = useState(0);
    const [txHash, setTxHash] = useState("");
    const [balance, setBalance] = useState("0");

    const nav = useNavigate();
    const toast = useToast();
    // const pebOptions = {
    //     color: '#f3f3f3',
    //     type: 'triangle',
    //     easing: 'easeOutQuart',
    //     size: 6,
    //     particlesAmountCoefficient: 4,
    //     oscillationCoefficient: 2
    //   }
    //const chainIdName = EvmRoutes.ethereum.title;
    // const chainId = networkInfo.chainId;
    // const etherscanPrefix = isSepolia ? "sepolia." : "";
    // const openseaPrefix = isSepolia ? "testnets." : "";

    const contractAddress: string = networkInfo.contract;
    const wallet = ViewData.evmWallet;
    let contract: ethers.Contract;
    // let tryLoaded = false;


    useEffect(() => {
        init();
    });

    const reset = () => {
        setRandomText(NotSet);
        setTheHash(NotSet);
        setPrize(0);
        setTxHash("");
    }

    const processEventOnBase = (account: string) => {
        if(!contract)
            return;
        contract.on('onMinted', (message, msgHash, amt, event) => {
            if(message && message.length > 20 && !message.endsWith(account.toLocaleLowerCase())) { // not from current address
                return;
            }
            const amount = Number(amt);
            toast({
                title: 'Congratulations!',
                description: `You have already minted ${amount} $DDDD!`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setRandomText(message);
            setTheHash(msgHash);
            setPrize(amount);

            event.removeListener();
        });
    }

    const mint = async () => {
        if (!wallet) {
            toast({
                title: 'Error',
                description: "The wallet object is null/undefined.",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        reset(); // reset UI

        const ctx = await wallet.getContract(contractAddress, abi);
        contract = ctx.contract;
        // set event
        if(chainInfo.name === "base")
            processEventOnBase(wallet.account);
        else {
            contract.on('onMinted', (minter, message, msgHash, amt, event) => {
                if(minter.toLocaleLowerCase() !== wallet.account.toLocaleLowerCase()) {
                    return;
                }
                const amount = Number(amt);
                toast({
                    title: 'Congratulations!',
                    description: `You have already minted ${amount} $DDDD!`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setRandomText(message);
                setTheHash(msgHash);
                setPrize(amount);
    
                event.removeListener();
            });
        }
        
        // mint
        setMinting(true);
        try {
            const receipt = await wallet.mint(contract, toast);
            setTxHash(receipt);
            await loadBalance();
        }
        finally {
            setMinting(false);
        }
    }

    const loadBalance = async () => {
        if (!wallet) return;
        const eth = await wallet.getBalance(abi, contractAddress, wallet.account);
        //console.log(`token balance: ${eth} wei`);
        const bal = ethers.formatEther(eth);
        //console.log(`token balance: ${bal} ETH`);
        setBalance(bal);
    }

    const init = async () => {
        if (wallet) {
            await loadBalance();
        }
    }

    return (
        <VStack spacing={4} maxW="960px" mx="auto" h="800px">
            <NavBar />
            <Flex mb={3} mt={3} alignItems="center">
                <Button as="a" leftIcon={<ArrowBackIcon />} colorScheme='teal' variant='outline'
                    onClick={() => nav(RoutesData.SelectChain)}>
                    Change Chain/Network
                </Button>
            </Flex>
            <HStack width="100%" spacing={4} mt={4} alignItems="center">
                <Card width="350px" shadow="md">
                    <CardBody>
                        <Image width="300px"
                            src='/images/004.jpg'
                            alt='DDDD'
                            borderRadius='lg'
                        />
                        <Stack mt='6' spacing='6'>
                            <Heading size='md'>国人经典 Meme</Heading>
                            {/* <UnorderedList>
                                    <ListItem>大佬，求求了！</ListItem>
                                    <ListItem>带带弟弟！</ListItem>
                                    <ListItem>大佬喝茶！🍵</ListItem>
                                    <ListItem>带带妹妹！</ListItem>
                                </UnorderedList> */}
                            <HStack fontSize='2xl'>
                                <Text>Balance: </Text>
                                <Text color="gold">{balance}</Text>
                                <Text>$DDDD</Text>
                            </HStack>
                        </Stack>
                    </CardBody>
                    <Divider color="lightgray" />
                    <CardFooter>
                        <Center>
                            <Button variant='solid' colorScheme='yellow' isLoading={minting} onClick={mint}>
                                ♥ DDDD ♥
                            </Button>
                            {/* <ParticleEffectButton  {...pebOptions} >🙏 DDDD</ParticleEffectButton> */}
                        </Center>
                    </CardFooter>
                </Card>

                <Stepper orientation='vertical' height='630px' gap='0' padding="8px" index={-1} shadow="md">
                    <Step>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>
                        <Box flexShrink='0' width="350px">
                            <StepTitle>Random Text</StepTitle>
                            <StepDescription>{randomText}</StepDescription>
                        </Box>
                        <StepSeparator />
                    </Step>
                    <Step>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>
                        <Box flexShrink='0' width="350px">
                            <StepTitle>Hash</StepTitle>
                            <StepDescription>{theHash}</StepDescription>
                        </Box>
                        <StepSeparator />
                    </Step>
                    {prize > 0 ? <Step>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>
                        <Box flexShrink='0' width="350px">
                            <StepTitle>🎉 Got Prize: {prize} $DDDD 🎉</StepTitle>
                            <StepDescription>
                                {txHash && txHash.length > 4 ? <Link href={`${networkInfo.explorer}tx/${txHash}`}>{txHash}</Link> : ""}
                            </StepDescription>
                        </Box>
                        <StepSeparator />
                    </Step> : null}
                </Stepper>
            </HStack>
            <Flex mb={3} mt={3} alignItems="center" width="100%">
                <Card width="350px" shadow="md">
                    <CardHeader>
                        <Heading size='md'>Contract</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>{networkInfo.contract}</Text>
                    </CardBody>
                    <CardFooter>
                        <Button as="a" href={`${networkInfo.explorer}address/${networkInfo.contract}`}>View</Button>
                    </CardFooter>
                </Card>
            </Flex>
            <Footer />
        </VStack>
    );
}