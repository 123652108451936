export const RoutesData = {
    Home: "/",
    SelectChain: "/select-chain",
    Start: "/start",
}

const getChainInfo = (networkInfo: any) => {
    if(networkInfo.mainnet.contract.length === 0) return networkInfo.testnet;
    else return networkInfo.mainnet;
}
