import { Badge, CardHeader, HStack, Heading, Text } from '@chakra-ui/react'
import React from "react";

type ChainNetworkBadgeType = {
    testnet: boolean;
}
export const ChainNetworkBadge = ({ testnet }: ChainNetworkBadgeType) => {
    if(testnet) return (
        <Badge variant='outline'>Testnet</Badge>
    );
    else return (
        <Badge colorScheme='green'>Mainnet</Badge>
    );
}

type ChainCardHeaderType = {
    title: string;
    icon: any;
    mainnetInfo: any;
}
export const ChainCardHeader = ({ title, icon, mainnetInfo }: ChainCardHeaderType) => {
    const isTestnet = mainnetInfo.contract.length === 0;
    if(!icon)
    return (
        <CardHeader>
            <Heading size='md'>
                <HStack>
                    <Text>{title}</Text>
                    <ChainNetworkBadge testnet={isTestnet} />
                </HStack>
            </Heading>
        </CardHeader>
    );

    return (
        <CardHeader>
            <Heading size='md'>
                <HStack>
                    {icon}
                    <Text>{title}</Text>
                    <ChainNetworkBadge testnet={isTestnet} />
                </HStack>
            </Heading>
        </CardHeader>
    );
}