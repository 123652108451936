import { EvmWallet, Wallet } from "../client/Wallet";
import { ChainsInfo } from "./ChainsInfo";

export class ViewData {
    static evmWallet: EvmWallet | null;
    static ethAddress: string = "";
    static ckbAddress: string = "";
    static loggedWithJoyID: boolean = false;
    static isEvmDeployer: boolean = false;

    static chainInfo: any = ChainsInfo.base;
    static evmNetworkId: string = ChainsInfo.base.testnet.requestParams.chainId;
    static evmNetworkInfo: any = ChainsInfo.base.testnet;

    static starknetAddress: string = "";

    static did: string = "";
    // static didENS: string = "";
    // static didDotBit: string = "";

}